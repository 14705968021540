<template>
  <div>
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          title="Riwayat Pembelian Buku"
          subtitle="Daftar riwayat pemebelian buku"
          :no-add-button="noAddButton"
          :headers="headers"
          :items="bookPurchaseHistories"
          :search="search"
          :page="page"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          @show-detail="showDetail"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        ></DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="600"
      :hidden-actions="false"
      header="Detail Riwayat Pembelian  Buku"
      @close-modal="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <div class="d-flex mb-4">
              <div class="me-4">
                <h4 class="font-weight-light mb-2">
                  Tanggal Beli
                </h4>
                <h4 class="font-weight-light">
                  Nama Sekolah
                </h4>
              </div>
              <div>
                <h4 class="mb-2">
                  : {{ dateFormat(bookPurchaseHistory.date_purchased) }}
                </h4>
                <h4>: {{ bookPurchaseHistory.name }}</h4>
              </div>
            </div>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <div class="d-flex">
              <div class="me-4">
                <h4 class="font-weight-light mb-2">
                  Jenjang
                </h4>
                <h4 class="font-weight-light">
                  Jumlah Buku
                </h4>
              </div>
              <div>
                <h4 class="mb-2">
                  : {{ bookPurchaseHistory.jenjang }}
                </h4>
                <h4>: {{ bookPurchaseHistory.book_purchased }}</h4>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="align-center">
          <v-col md="5">
            <h4>Buku Yang Dibeli</h4>
            <span>List Buku yang sudah dibeli</span>
          </v-col>
          <v-col
            md="7"
          >
            <div class="mt-6">
              <v-text-field
                v-model="search"
                label="Cari Buku"
                outlined
                dense
                @change="searchBookHandler"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-card>
          <v-card-title>
            List Buku
          </v-card-title>
          <v-simple-table
            height="300"
            style="overflow:auto;"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    No
                  </th>
                  <th class="text-left">
                    Nama Buku
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="book in books"
                  :key="book.uuid"
                >
                  <td>{{ book.index }}</td>
                  <td>{{ book.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import dateTimeFormat from '@/utils/date/dateTimeFormat'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiArrowLeft } from '@mdi/js'

export default {
  name: 'BookPurchaseHistory',
  components: {
    MainCard,
    DataTablePagination,
    ModalDialog,
    SkeletonLoaderTable,
  },
  data() {
    return {
      icons: { mdiArrowLeft },
      noAddButton: true,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Tanggal', value: 'created_at' },
        { text: 'Nama Buku', value: 'library_name' },
        { text: 'Nama Sekolah', value: 'name' },
        { text: 'Jenjang', value: 'jenjang' },
        { text: 'Jumlah Buku', value: 'purchased_book' },
        { text: 'Aksi', value: 'detail' },
      ],
      page: 1,
      search: '',
      totalItems: 0,
      totalPages: 0,
      isLoadingData: false,
      isLoadingTable: true,
      modalDialog: false,
      bookPurchaseHistories: [],
      bookPurchaseHistory: {
        school: {},
      },
      books: [],
      package_uuid: '',
      school_uuid: '',
      filterQuery: {
        search: '',
      },
      filterQueryBook: {
        search: '',
      },
      service: 'librarypurchase',
    }
  },
  watch: {
    page: {
      handler() {
        this.listBookPurchaseHistory()
      },
    },
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.getPackage(this.package_uuid, { search: this.search })
          }, 500)
        }
      },
    },
  },
  mounted() {
    this.listBookPurchaseHistory()
  },
  methods: {
    async showDetail(uuid, date) {
      this.school_uuid = uuid
      this.date = date
      this.modalDialog = true
      await this.getPackage()
    },
    async getPackage(params = {}) {
      await this.$services.ApiServices.listParams(this.service, this.school_uuid, {
        ...params,
        date: this.date,
      }).then(({ data }) => {
        this.bookPurchaseHistory = data.data
        this.books = data.data.books.map((book, index) => ({
          ...book,
          index: index + 1,
        }))
      })
    },
    async listBookPurchaseHistory(params = {}) {
      this.isLoadingData = true

      await this.$services.ApiServices.list(this.service, { ...params, page: this.page }).then(
        ({ data }) => {
          this.bookPurchaseHistories = data.data.map((bookPurchaseHistory, index) => ({
            ...bookPurchaseHistory,
            index: index + 1,
            created_at: dateTimeFormat(bookPurchaseHistory.created_at, '/'),
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingData = false
      this.isLoadingTable = false
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.listBookPurchaseHistory(this.filterQuery)
    },
    async searchBookHandler() {
      this.filterQueryBook.search = this.search
      await this.getPackage(this.filterQueryBook)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    dateFormat(time) {
      return dateTimeFormat(time, '/')
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
